/**
 * This file provides functions to call our lambdaAPI.
 */
import axios from 'axios';

const lambdaAPI = {}

const BASE_URL = process.env.GATSBY_LAMBDA_API;
const DONATION_PATH = process.env.GATSBY_DONATION_PATH;
const DONATION_TYPE_KEY = 'donationType';
const FEED_BASE_URL = process.env.GATSBY_FEED_BASE_URL || 'https://0b9rznfmv6.execute-api.ap-southeast-2.amazonaws.com/dev';

function handleError(error) {
    console.error(error)
    throw error
}

lambdaAPI.donate = (data) => {
    const url = `${BASE_URL}${DONATION_PATH}/flo2cash`
    return axios.post(url, data)
        .then(res => {
            // the getStatus endpoint needs this param so we save it in sessionStorage.
            sessionStorage.setItem(DONATION_TYPE_KEY, data.donationType)
            return res.data
        })
        .catch(handleError)
}

lambdaAPI.getStatus = (paymentId) => {
    const donationType = sessionStorage.getItem(DONATION_TYPE_KEY) 

    const url = `${BASE_URL}${DONATION_PATH}/flo2cash-notification?id=${paymentId}&donationType=${donationType}`
    return axios.get(url)
        .then(res => {
            const result = res.data.result;
            if(result.status === 'complete' || result.errorCode != null) {
                sessionStorage.removeItem(DONATION_TYPE_KEY)
            }
            return res.data
        })
        .catch(handleError)
}

lambdaAPI.getPaymentStatus = (paymentId, donationType, submissionId) => {
    const url = `${BASE_URL}${DONATION_PATH}/flo2cash-notification?id=${paymentId}&donationType=${donationType}&submissionId=${submissionId}`
    return axios.get(url)
        .then(res => {
            return res.data
        })
        .catch(handleError)
}

lambdaAPI.getTwitterFeed = (usersection, id, maxResults, paginationToken) => {
    let url = `${FEED_BASE_URL}/tweets?`;
    if (usersection) {
        url = url + `&usersection=${usersection}`;
    }
    if (id) {
        url = url + `&account_id=${id}`;
    }
    if (maxResults) {
        url = url + `&max_results=${maxResults}`;
    }
    if (paginationToken) {
        url = url + `&pagination_token=${paginationToken}`
    }
    return axios.get(url)
        .then(res => {
            return res.data;
        })
        .catch(handleError)
}

lambdaAPI.getmetaPosts = (usersection, app, limit) => {
    let url = `${FEED_BASE_URL}/meta?app=${app}`;
    if (usersection) {
        url = url + `&usersection=${usersection}`;
    }
    if (limit) {
        url = url + `&limit=${limit}`;
    }
    return axios.get(url)
        .then(res => {
            return res.data;
        })
    .catch(handleError)
}

export default lambdaAPI