import React, {PureComponent} from 'react'
import Helmet from 'react-helmet'
import has from 'lodash/has'
import get from 'lodash/get'
import { navigate, graphql } from 'gatsby'
import PageLayout from '../components/layouts/PageLayout'
import { SearchHelper } from '../helpers'
import lambdaAPI from '../lambdaAPI'
import EventTracker, {facebookTracking, FACEBOOK_EVENTS } from './../helpers/EventTracker'
import 'react-phone-number-input/style.css'
import { SECTIONS } from './../utils/url'

import airAmbulance from './../assets/images/air-ambulance.jpg'
import mercuryStarshipLockup from './../assets/images/mercury-starship-lockup.png'
import asbStarshipLockup from './../assets/images/asb-starship-lockup.png'
import {FORM_KEYS} from '../variables';
import '../assets/styles/donate.scss';

const DEFAULT_AMOUNT = 50
export const MINIMUM_AMOUNT = 4

export default class Donate extends PureComponent {

    reasonsList = [
        "I want to support the Air Ambulance Appeal",
        "I want to support the Bake it Better Appeal",
        "I've had a family member or friend in Starship",
        "I received my latest Friendship Newsletter",
        "I am giving in memory of a loved one",
        "I am giving in lieu of a gift",
        "I have another reason (please provide details below)",
        "I'm donating money that's been raised for Starship",
    ]

    partnerConfig = {
        Mercury: {
            reason: "I'm a Mercury customer",
            logo: mercuryStarshipLockup,
        },
        ASB: {
            reason: "I saw the ASB campaign",
            logo: asbStarshipLockup,
            amount: 20
        }
    }

    state = {
        amount: DEFAULT_AMOUNT,
        isChangingAmount: false,
        isPerson: true,
        isSubmitting: false,
        firstName: '',
        lastName: '',
        partnerTitle: '',
        partnerFirstName: '',
        partnerLastName: '',
        title: '',
        isRecurring: false,
        customReason: '',
        email: '',
        phone: '',
        organisation: '',
        cancelledMsg: false,
        comment: '',
        shouldSave: false,
        reason: '',
        prefillValue: null,
    }

    ENTITY_TYPES = {
        PERSONAL: 'personal',
        COMPANY: 'company',
    }

    RECAPTCHA_ACTION_NAME = 'donation_form'

    DEFAULT_FORM_SLUG = process.env.GATSBY_DEFAULT_FORM_SLUG;

    componentDidMount() {
        EventTracker.ready().then(() => {
            facebookTracking(FACEBOOK_EVENTS.AddToCart)
        }).catch(err => console.error);
        

        this.waitForCaptchaScriptInterval = setInterval(() => {
            if(!has(window, 'grecaptcha.ready')) {
                return;
            }

            clearInterval(this.waitForCaptchaScriptInterval)
            
            window.grecaptcha.ready(() => {
                this.initGoogleRecaptcha()
                // Reset reCAPTCHA every 2 mintues so it doens't expire
                setInterval(() => { 
                    this.initGoogleRecaptcha()
                }, 2 * 60 * 1000 )
            })
        }, 100)
            
        const storageState = this.getStateFromStorage();
        this.setState(storageState)
        this.getStateFromStorageForPrefill();
    }

    initGoogleRecaptcha() {
        window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_KEY, {action: this.RECAPTCHA_ACTION_NAME}).then(token => {
            this.recaptchaToken = token
        })
    }

    definePrefillAmount(amount) {
        if (amount === 25 || amount === 50 || amount === 75) {
            return true
        }
        return false;
    }

    prefillKeyAmount(amount) {
        switch (amount) {
            case 25:
                return FORM_KEYS.AMOUNT_25;
            case 50:
                return FORM_KEYS.AMOUNT_50;
            default:
                return FORM_KEYS.AMOUNT_75;
        }
    }

    getStateFromStorageForPrefill() {
        const { pageContext, location} = this.props;
        const qparams = SearchHelper.queryParamsToObject(location.search)
        const shouldSaveValue = localStorage.getItem('shouldSaveLocal') === "true" || this.state.shouldSave;
        const config = pageContext.partner ? this.partnerConfig[pageContext.partner] : {};
        const state = {}
        state.amount = parseInt(qparams.amount) || config.amount || DEFAULT_AMOUNT;
        state.isRecurring = (!!qparams.recurring && (qparams.recurring.toUpperCase() !== 'NEVER')) || this.state.isRecurring;
        state.customReason = qparams.reason || config.reason || '';
        state.cancelledMsg = qparams.cancelled === "true";
        state.comment = qparams.message || '';
        state.shouldSave = shouldSaveValue;
        state.title = localStorage.getItem('title') || '';
        state.firstName = localStorage.getItem('firstName') || '';
        state.lastName = localStorage.getItem('lastName') || '';
        state.partnerTitle = localStorage.getItem('partnerTitle') || '';
        state.partnerFirstName = localStorage.getItem('partnerFirstName') || '';
        state.partnerLastName = localStorage.getItem('partnerLastName') || '';
        state.email = localStorage.getItem('email') || '';
        state.phone = localStorage.getItem('phone') || '';
        state.isPerson = localStorage.getItem('isPerson') !== "false" || this.state.isPerson;
        state.organisation = localStorage.getItem('organisation') || '';
        const street = localStorage.getItem('street') || '';
        const city =  localStorage.getItem('city') || '';
        const suburb =  localStorage.getItem('suburb') || '';
        const postcode = localStorage.getItem('postcode') || '';
        const selectedCountry = JSON.parse(localStorage.getItem('selectedCountry'));
        const address = `${street}, ${suburb}, ${city}, ${postcode}, ${(selectedCountry && selectedCountry.label) ? selectedCountry.label : ''}`;
        const title = state.title ? `${FORM_KEYS.TITLE}=${state.title}&` : ``;
        const prefillAmount = this.definePrefillAmount(state.amount) ? `${FORM_KEYS.WIDGET_AMOUNT}=${this.prefillKeyAmount(state.amount)}` : 
            `${FORM_KEYS.WIDGET_AMOUNT}=${FORM_KEYS.AMOUNT_OTHER}&${FORM_KEYS.CUSTOM_AMOUNT}=${state.amount}`;

        const prefillValue = `${FORM_KEYS.FIRST_NAME}=${state.firstName}&${FORM_KEYS.LAST_NAME}=${state.lastName}&${FORM_KEYS.EMAIL}=${state.email}&` +
            `${FORM_KEYS.PHONE_NUMBER}=${state.phone}&${FORM_KEYS.PARTNER_TITLE}=${state.partnerTitle}&${FORM_KEYS.PARTNER_FIRST_NAME}=${state.partnerFirstName}&` +
            `${FORM_KEYS.PARTNER_LAST_NAME}=${state.partnerLastName}&${FORM_KEYS.REASON}=${state.reason}&${FORM_KEYS.REMEMBER}=${state.shouldSave ? 'Yes' : 'No'}&` +
            `${title}${FORM_KEYS.ORGANIZATION}=${state.organisation}&${FORM_KEYS.ADDRESS}=${address}&${FORM_KEYS.FREQUENCY}=${state.isRecurring ? FORM_KEYS.RECURRING : FORM_KEYS.ONCE}&` +
            `${FORM_KEYS.ENTITY_TYPE}=${state.isPerson ? FORM_KEYS.ENTITY_TYPE_PERSONAL : FORM_KEYS.ENTITY_TYPE_COMPANY}&${prefillAmount}`;
        
        this.setState({prefillValue});
    }

    getStateFromStorage() {
        const { pageContext, location} = this.props;
        const qparams = SearchHelper.queryParamsToObject(location.search)
        const shouldSaveValue = localStorage.getItem('shouldSaveLocal') === "true" || this.state.shouldSave;

        let storage = null;
        const cancelledOrFailed = qparams.cancelled === "true" || qparams.failed === "true";
        if(shouldSaveValue) {
            //Had "remember me" from last time
            storage = localStorage;
        } else if(cancelledOrFailed) {
            //If the transaction was cancelled, restore user data from session storage (unless it was already saved from local storage)
            storage = sessionStorage;
        }

        const config = pageContext.partner ? this.partnerConfig[pageContext.partner] : {};

        const state = {}
        state.amount = parseInt(qparams.amount) || config.amount || DEFAULT_AMOUNT;
        state.isRecurring = (!!qparams.recurring && (qparams.recurring.toUpperCase() !== 'NEVER')) || this.state.isRecurring;
        state.customReason = qparams.reason || config.reason || '';
        state.cancelledMsg = qparams.cancelled === "true";
        state.comment = qparams.message || '';
        state.shouldSave = shouldSaveValue;

        if(storage) {
            //Always use query params first
            state.amount= parseInt(qparams.amount) || storage.getItem('amount') || this.state.amount;
            state.title = storage.getItem('title') || '';
            state.firstName = storage.getItem('firstName') || '';
            state.lastName = storage.getItem('lastName') || '';
            state.partnerTitle = storage.getItem('partnerTitle') || '';
            state.partnerFirstName = storage.getItem('partnerFirstName') || '';
            state.partnerLastName = storage.getItem('partnerLastName') || '';
            state.email = storage.getItem('email') || '';
            state.phone = storage.getItem('phone') || '';
            state.isPerson = storage.getItem('isPerson') !== "false" || this.state.isPerson;
            state.organisation = storage.getItem('organisation') || '';

            if(cancelledOrFailed) {
                state.customReason = sessionStorage.getItem('reason') || '';
                state.comment = sessionStorage.getItem('comment') || '';
            }
        }
        return state;
    }

    onEntityTypeChange = e => {
        this.setState({
            isPerson: e.target.value === this.ENTITY_TYPES.PERSONAL
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value
        });
    }

    handleDataStorageChange = (event) => {
        const flipShouldSave = !this.state.shouldSave;
        this.setState(prevState => ({ shouldSave: flipShouldSave }));

        if(flipShouldSave) {
            // We only use local storage for these saved fields, so it's safe to clear it
            localStorage.clear();
        }

        localStorage.setItem('shouldSaveLocal', flipShouldSave.toString());
    }

    changeAmount = () => {
        if(this.state.isChangingAmount) {
            return;
        }

        this.setState({
            isChangingAmount: true
        }, () => this.amountInput.focus())
    }

    onAmountKeyPress = (e) => {
        if(e.key === 'Enter') {
            this.setState({
                amount: e.target.value,
                isChangingAmount: false,
            })
        } else if(e.key === 'Escape') {
            this.setState({
                isChangingAmount: false,
            })
        }
    }

    onAmountBlur = () => {
        this.setState({ isChangingAmount: false })
        if(this.state.amount < this.MINIMUM_AMOUNT) {
            this.setState({ amount: this.MINIMUM_AMOUNT })
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if(this.state.isSubmitting) {
            return
        }

        this.setState({ isSubmitting: true });

        // TODO: This should save one object as a single entry.
        if(this.state.shouldSave) {
            localStorage.setItem('firstName', this.state.firstName)
            localStorage.setItem('lastName', this.state.lastName)
            localStorage.setItem('partnerFirstName', this.state.partnerFirstName)
            localStorage.setItem('partnerLastName', this.state.partnerLastName)
            localStorage.setItem('title', this.state.title)
            localStorage.setItem('partnerTitle', this.state.partnerTitle)
            localStorage.setItem('isPerson', this.state.isPerson)
            localStorage.setItem('amount', this.state.amount)
            localStorage.setItem('email', this.state.email)
            localStorage.setItem('phone', this.state.phone)
            localStorage.setItem('organisation', this.state.organisation)
            localStorage.setItem('isPerson', this.state.isPerson)


            // From AddressFields.js sessionStorage, transfer to localStorage
            localStorage.setItem('street', sessionStorage.getItem('street') || '')
            localStorage.setItem('city', sessionStorage.getItem('city') || '')
            localStorage.setItem('suburb', sessionStorage.getItem('suburb') || '')
            localStorage.setItem('postcode', sessionStorage.getItem('postcode') || '')
            localStorage.setItem('selectedCountry', sessionStorage.getItem('selectedCountry') || '')
            localStorage.setItem('isManualAddress', "true") // always true on save
        } else {
            // Save in session storage, in case of cancellation
            sessionStorage.setItem('firstName', this.state.firstName)
            sessionStorage.setItem('lastName', this.state.lastName)
            sessionStorage.setItem('partnerFirstName', this.state.partnerFirstName)
            sessionStorage.setItem('partnerLastName', this.state.partnerLastName)
            sessionStorage.setItem('title', this.state.title)
            sessionStorage.setItem('partnerTitle', this.state.partnerTitle)
            sessionStorage.setItem('isPerson', this.state.isPerson)
            sessionStorage.setItem('amount', this.state.amount)
            sessionStorage.setItem('email', this.state.email)
            sessionStorage.setItem('phone', this.state.phone)
            sessionStorage.setItem('organisation', this.state.organisation)
            sessionStorage.setItem('isPerson', this.state.isPerson)
            sessionStorage.setItem('isManualAddress', "true") // always true on save
        }

        //Save reason to session in all cases
        sessionStorage.setItem('reason', this.state.reason || this.state.customReason) 
        sessionStorage.setItem('comment', this.state.comment) 

        const data = this.getData()
        lambdaAPI.donate(data)
            .then(url => {
                if(typeof url === "string" ) {
                    facebookTracking(FACEBOOK_EVENTS.AddToCart)
                    window.location.href = url
                }
                else {
                    throw url.errorMessage;
                }

            })
            .catch(error => {
                this.setState({ isSubmitting: false })
                navigate(`${SECTIONS.SUPPORT_STARSHIP.url}/payment-failed`, { replace: true, state: {message: error} })
            })
    }

    saveLocalSessionStorage(key, value) {
        localStorage.setItem(key, value);
        sessionStorage.setItem(key, value);
    }

    getData() {
        return {
            ...this.getFormData(),
            ...SearchHelper.getUTMdata(),
            token: this.recaptchaToken
        }
    }

    getFormData() {
        const formData = new FormData(this.formEl);
        const data = {};

        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }

        if(data.isRecurring) {
            data.frequency = 'monthly'
            delete data['isRecurring']
            data.donationType = data.paymentMethod
        } else {
            data.donationType = 'single'
        }

        delete data['paymentMethod']

        data.comment = data.comment.replace(/(\r\n|\n|\r)/gm, " ")

        return data;
    }

    render() {
        const { prefillValue } = this.state
        const { pageContext, location } = this.props
        
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        let header = null;
        if (pageContext.partner) {
            header = <>
                <p className="text-center">
                    <img 
                        src={this.partnerConfig[pageContext.partner].logo} 
                        alt={`${pageContext.partner} Starship Lockup`} 
                        title={`${pageContext.partner} Starship Lockup`} 
                        className="w-50" 
                    />
                </p>
                <h1 className="mb-4">Help Starship save lives</h1>
            </>;
        }
        else if (this.state.customReason === 'The Starship National Air Ambulance') {
            header = <>
                <h1 className="mb-4">Help keep our Air Ambulance flying</h1>
                <p><img src={airAmbulance} alt="Air Ambulance" title="Air Ambulance" className="w-100" /></p>
                <p className="mb-4 mb-lg-5">Your generous support will help our children get life-saving treatment and care when they need it. Our Starship National Air Ambulance service is relied on by critically ill children and their families from all over New Zealand. A donation of $50, $100 or $150 will help us reach our target for this year, or your ongoing monthly donation will ensure the Starship National Air Ambulance service will be ready to go at a moment’s notice year round for every child in urgent need. Thank you for your generosity.</p>
            </>;
        }
        else {
            header = <h1 className="mb-4">Thank you for your generous gift</h1>;
        }
        return (
            <PageLayout className="donate" activeSection={SECTIONS.SUPPORT_STARSHIP.key} sitemap={sitemap} >
                <Helmet title="Donate - Starship"
                script={[
                  {src: `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_KEY}`,
                  type: 'text/javascript',
                  defer:true}
                ]}/>
                <main className="wrapper">
                    <div className="content">
                        {header}
                        <div class="raisely-donate" data-campaign-path={process.env.GATSBY_DONATION_CAMPAIGN_PATH} data-profile="" data-width="100%" data-height="800">
                            <script>
                                { typeof window !== 'undefined' && (function() {var script = document.createElement('script'); script.src = "https://cdn.raisely.com/v3/public/embed.js"; document.body.appendChild(script); })()}
                            </script>
                        </div>
                    </div>
                </main>
            </PageLayout>
         )
    }
}

export const pageQuery = graphql`
query getSiteMapDonation {
  allContentfulWidgetSitemap {
      edges {
          node {
              contentful_id
              userSection
              title
              slug
              links {
                  __typename
                  ... on ContentfulPageCustom {
                      slug
                      title
                      contentful_id
                      userSection
                  }
                  ... on ContentfulPageDirectoryOfServices {
                      id
                      title
                  }
                  ... on ContentfulPageDonation {
                      slug
                      title
                      contentful_id
                  }
                  ... on ContentfulPageGeneric {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGroup {
                      slug
                      title
                      userSection
                  }
                  ... on ContentfulPageGuideline {
                      title
                      slug
                  }
                  ... on ContentfulPageWidgets {
                      title
                      slug
                      userSection
                  }
              }
          }
      }
  }
}`